
import { Order_order_Order_performerApplications_performer } from "@/api/order/__generated__/Order";
import { PropType, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import {
  PlusOutlined,
  CheckCircleFilled,
  EyeOutlined,
} from "@ant-design/icons-vue";
import Avatar from "@/shared/components/Avatar.vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import PerformerRating from "@/shared/components/Performers/PerformerRating.vue";
import PerformerBadgeList from "@/shared/components/Performers/PerformerBadgeList.vue";
import PerformerIndustries from "@/shared/components/Performers/PerformerIndustries.vue";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import message from "@/shared/utils/message";

export default defineComponent({
  emits: ["select", "unselect"],
  props: {
    performer:
      Object as PropType<Order_order_Order_performerApplications_performer>,
    selected: Boolean,
    loading: Boolean,
    /** Disable button, but only if not `selected` (allows "unselect") */
    disabled: Boolean,
    forceDisabled: Boolean,
    forceDisabledMessage: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isXs } = useWindowSizeChecker();

    const { redirectToPerformerDetails } = useRedirectHelper();

    const handleSelectToggle = () => {
      if (props.selected) {
        emit("unselect");
      } else {
        emit("select");
      }
    };

    const showForceDisabledMessage = () => {
      if (props.forceDisabled && props.forceDisabledMessage) {
        message["warning"](props.forceDisabledMessage);
      }
    };

    return {
      t,
      isXs,
      handleSelectToggle,
      showForceDisabledMessage,
      redirectToPerformerDetails,
    };
  },
  components: {
    PlusOutlined,
    CheckCircleFilled,
    Avatar,
    EyeOutlined,
    PerformerRating,
    PerformerBadgeList,
    PerformerIndustries,
  },
});
