
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CascadingAvatars, {
  CascadingAvatarItem,
} from "@/shared/components/CascadingAvatars.vue";
import Card from "@/shared/components/Card.vue";

export default defineComponent({
  props: {
    avatars: Array as PropType<CascadingAvatarItem[]>,
    max: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const isCompleted = computed(
      () => (props.avatars?.length ?? 0) === props.max
    );

    return { t, isCompleted };
  },
  components: { CascadingAvatars, Card },
});
