
import { CascadingAvatarItem } from "@/shared/components/CascadingAvatars.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import PerformerApplicationCard from "@/shared/components/Performers/PerformerApplicationCard.vue";
import SelectedPerformersCounter from "@/shared/components/SelectedPerformersCounter.vue";
import { useOrder } from "@/shared/composables/useOrder";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { config } from "@/shared/utils/config";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const orderId = ref(String(route.params.orderId));

    const {
      order,
      orderLoading,
      performerApplications,
      selectedPerformers,
      addPerformer,
      removePerformer,
      addPerformerToOrderLoading,
      removePerformerFromOrderLoading,
    } = useOrder({ orderId });

    const { currentSubscriptionPlan } = useSubscriptionPlans();

    const maxPerformerCount = computed(
      () =>
        order.value?.quotation?.numOfPerformers ??
        currentSubscriptionPlan.value?.maxPerformerCount ??
        0
    );

    const avatars = computed<CascadingAvatarItem[]>(
      () =>
        selectedPerformers.value.map((performer) => ({
          src: performer?.performerProfile?.avatar ?? "",
        })) ?? []
    );

    const selectLoading = computed(
      () =>
        addPerformerToOrderLoading.value ||
        removePerformerFromOrderLoading.value
    );

    const canAddMorePerformers = computed(
      () => selectedPerformers.value.length < maxPerformerCount.value
    );

    const handleAddPerformer = (id) => {
      addPerformer({ orderId: orderId.value, userId: id });
    };

    const handleRemovePerformer = (id) => {
      removePerformer({ orderId: orderId.value, userId: id });
    };

    const isEmpty = computed(() => !performerApplications.value.length);

    const hasChoosePerformersAddon = computed(
      () => !!order.value?.quotation?.choosePerformers
    );
    return {
      t,
      config,
      avatars,
      maxPerformerCount,
      isEmpty,
      canAddMorePerformers,
      hasChoosePerformersAddon,

      order,
      orderLoading,
      performerApplications,
      selectedPerformers,
      handleAddPerformer,
      handleRemovePerformer,
      selectLoading,
    };
  },
  components: {
    PageLayout,
    SelectedPerformersCounter,
    PerformerApplicationCard,
  },
});
