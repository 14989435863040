import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-right" }
const _hoisted_2 = { class: "flex flex-col gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PerformerRating = _resolveComponent("PerformerRating")!
  const _component_PerformerBadgeList = _resolveComponent("PerformerBadgeList")!
  const _component_PerformerIndustries = _resolveComponent("PerformerIndustries")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_CheckCircleFilled = _resolveComponent("CheckCircleFilled")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "performer-application-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_button, {
          type: "secondary",
          size: "small",
          "icon-only": "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToPerformerDetails({ id: _ctx.performer?.id }))),
          class: "px-2 mb-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EyeOutlined)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_a_row, {
        gutter: [16, 16],
        class: "flex-grow",
        wrap: _ctx.isXs
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { flex: "128px" }, {
            default: _withCtx(() => [
              _createVNode(_component_Avatar, {
                "avatar-src": _ctx.performer?.performerProfile?.avatar,
                class: "performer-application-card__avatar"
              }, null, 8, ["avatar-src"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            flex: _ctx.isXs ? '100%' : 'auto',
            class: "flex flex-col"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_a_typography_title, {
                    level: 5,
                    class: "mb-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.performer?.performerProfile?.nickname), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PerformerRating, {
                    performer: _ctx.performer,
                    class: "block"
                  }, null, 8, ["performer"])
                ]),
                _createVNode(_component_PerformerBadgeList, {
                  "performer-profile": _ctx.performer?.performerProfile,
                  "force-unlocked-state": ""
                }, null, 8, ["performer-profile"]),
                _createVNode(_component_PerformerIndustries, { performer: _ctx.performer }, null, 8, ["performer"])
              ]),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showForceDisabledMessage && _ctx.showForceDisabledMessage(...args))),
                class: _normalizeClass(["flex flex-grow mt-7", [_ctx.forceDisabled && 'cursor-not-allowed']])
              }, [
                _createVNode(_component_a_button, {
                  type: "primary",
                  loading: _ctx.loading,
                  disabled: _ctx.forceDisabled || (_ctx.disabled && !_ctx.selected),
                  onClick: _ctx.handleSelectToggle,
                  class: _normalizeClass([[
              'performer-application-card__select-button',
              _ctx.selected && 'performer-application-card__select-button--selected',
              _ctx.forceDisabled && 'pointer-events-none',
            ], "mt-auto"]),
                  block: ""
                }, {
                  icon: _withCtx(() => [
                    (!_ctx.selected)
                      ? (_openBlock(), _createBlock(_component_PlusOutlined, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_CheckCircleFilled, { key: 1 }))
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.selected ? _ctx.t("Selected") : _ctx.t("Select")), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled", "onClick", "class"])
              ], 2)
            ]),
            _: 1
          }, 8, ["flex"])
        ]),
        _: 1
      }, 8, ["wrap"])
    ]),
    _: 1
  }))
}