import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "text-center mt-12" }
const _hoisted_2 = {
  key: 1,
  class: "performer-applications-page__empty"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_SelectedPerformersCounter = _resolveComponent("SelectedPerformersCounter")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PerformerApplicationCard = _resolveComponent("PerformerApplicationCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    "back-button": "",
    onOnBack: _ctx.$router.back,
    class: "performer-applications-page"
  }, {
    default: _withCtx(() => [
      (_ctx.orderLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_typography_text, { class: "performer-applications-page__order-id block" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Order #{id}", { id: _ctx.order?.id })), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_typography_title, {
                level: 2,
                class: "my-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Select Model Creators")), 1)
                ]),
                _: 1
              }),
              (!_ctx.isEmpty)
                ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(
              "Below, you'll find the creators who applied to your brand's project."
            )) + " ", 1),
                      (_ctx.hasChoosePerformersAddon)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.t(
                "Please select the candidates you would like to collaborate with."
              )), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isEmpty)
              ? (_openBlock(), _createBlock(_component_SelectedPerformersCounter, {
                  key: 0,
                  avatars: _ctx.avatars,
                  max: _ctx.maxPerformerCount,
                  class: "mx-auto mt-8 mb-16"
                }, null, 8, ["avatars", "max"]))
              : _createCommentVNode("", true),
            (_ctx.isEmpty)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_a_typography_title, {
                    level: 3,
                    class: "text-color-2 m-0 mb-8"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.hasChoosePerformersAddon
              ? _ctx.t(
                  "The creators who applied for your project will be listed here in 24 hours."
                )
              : _ctx.t("We will assign creators within 24 hours.")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, { keypath: "For any questions or concerns, please email us at {email}" }, {
                        email: _withCtx(() => [
                          _createElementVNode("a", {
                            href: `mailto:${_ctx.config.merchantSupportEmailUrl}`
                          }, _toDisplayString(_ctx.config.merchantSupportEmailUrl), 9, _hoisted_3)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.hasChoosePerformersAddon)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        disabled: "",
                        class: "mt-8"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Choose Creators")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock(_component_a_row, {
                  key: 2,
                  gutter: [32, 16],
                  class: "mb-16"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performerApplications, (performer) => {
                      return (_openBlock(), _createBlock(_component_a_col, {
                        key: performer?.id,
                        span: 24,
                        lg: 12,
                        xl: 8
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PerformerApplicationCard, {
                            performer: performer,
                            selected: !!_ctx.selectedPerformers.find((p) => p.id === performer?.id),
                            loading: _ctx.selectLoading,
                            disabled: !_ctx.canAddMorePerformers,
                            onSelect: ($event: any) => (_ctx.handleAddPerformer(performer?.id)),
                            onUnselect: ($event: any) => (_ctx.handleRemovePerformer(performer?.id)),
                            class: "h-full",
                            "force-disabled": !_ctx.hasChoosePerformersAddon,
                            "force-disabled-message": 
              _ctx.t(
                'You need to purchase the Choose Creator Add-On. Please contact us for assistance.'
              )
            
                          }, null, 8, ["performer", "selected", "loading", "disabled", "onSelect", "onUnselect", "force-disabled", "force-disabled-message"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }))
          ], 64))
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}