import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_CascadingAvatars = _resolveComponent("CascadingAvatars")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "selected-performers-counter" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, {
        gutter: [30, 16],
        class: "text-center sm:text-left"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            span: 24,
            sm: 15
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_typography_text, { class: "selected-performers-counter__title" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Selected Model Creator(s)")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_typography_text, {
                class: _normalizeClass([
            'selected-performers-counter__count',
            _ctx.isCompleted && 'selected-performers-counter__count--completed',
          ])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("{selectedCount} / {maxCount}", {
              selectedCount: _ctx.avatars?.length,
              maxCount: _ctx.max,
            })), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 24,
            sm: 9
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CascadingAvatars, {
                avatars: _ctx.avatars,
                max: _ctx.max
              }, null, 8, ["avatars", "max"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}